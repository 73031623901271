<template>
  <div class="contents userInfo">
    <div class="title flexB">
      <h2>회원정보</h2>
    </div>
    <div class="box one">
      <div class="flex">
        <p>Title</p>
        <label>
          <input type="radio" name="Role" value="true" v-model="isAdmin" />Admin
        </label>
        <label>
          <input
            type="radio"
            name="Role"
            value="false"
            v-model="isAdmin"
          />Member
        </label>
      </div>
      <div class="flex">
        <p>First Name</p>
        <input type="text" v-model="firstName" />
      </div>
      <div class="flex">
        <p>Last Name</p>
        <input type="text" v-model="lastName" />
      </div>
      <div class="flex">
        <p>Email</p>
        <input type="text" v-model="email" disabled />
      </div>
      <!-- <div class="flex">
        <p>생년월일</p>
        <input type="text" v-model="birth" />
      </div>
      <div class="flex">
        <p>성별</p>
        <label> <input type="radio" value="M" v-model="gender" />남 </label>
        <label> <input type="radio" value="F" v-model="gender" />여 </label>
      </div>
      <div class="flex">
        <p>연락처</p>
        <input type="text" v-model="mobileNo" />
      </div> -->

      <div class="flex">
        <p>Member Since</p>
        <span>{{ moment(createdAt).format("YYYY-MM-DD HH:mm") }}</span>
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="submit">Update</button>
        <router-link to="/admin/users">
          <button class="right line">List</button>
        </router-link>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold">Search Results</h2>
      <table>
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 70%" />
          <col style="width: 20%" />
        </colgroup>
        <tr>
          <th>No.</th>
          <th style="text-align: left">Search Term</th>
          <th>Search Date</th>
        </tr>
        <tr v-for="(data, i) in wordList" :key="i">
          <td>{{ data.dataNum }}</td>
          <td style="text-align: left">{{ data.word }}</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD hh:mm") }}</td>
        </tr>
      </table>
      <NoData v-if="wordList.length < 1" />
      <div class="pagination" v-if="wordList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  fetchUserDetails,
  updateUserDetails,
  fetchUserWordList,
} from "@/api/admin";
// import { format } from "@/mixins/format";
import NoData from "@/components/contents/Nodata";
export default {
  // mixins: [format],
  name: "UserManage",
  components: { NoData },
  data() {
    return {
      moment: moment,
      // inputMode: "",
      // searchWord: "",
      total: 0,
      page: 1,
      userId: "",
      isAdmin: false,
      // isMarried: false,
      // username: "",
      firstName: "",
      lastName: "",
      email: "",
      createdAt: "",
      updatedAt: "",
      // gender: "",
      // job: "",
      // level: "",
      // address: "",
      // birth: "",
      //point: "",
      wordList: [],
      // loading: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
  },
  mounted() {
    // this.loading = true;
    this.userId = this.$route.query.id;
    this.getUserDetail();
    this.getUserWordList();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getUserWordList();
    },
    getUserDetail() {
      fetchUserDetails(this.userId).then((res) => {
        if (res.data.status == 200) {
          if (res.data.status == 200) {
            // console.log(res.data.data)
            this.isAdmin = res.data.data.isAdmin;
            this.firstName = res.data.data.firstName;
            this.lastName = res.data.data.lastName;
            this.email = res.data.data.email;
            this.createdAt = res.data.data.createdAt;
            this.updatedAt = res.data.data.updatedAt;
          } else if (res.data.status == 409) {
            // this.$router.push("/");
            return alert(res.data.message);
          }
        } else {
          alert(res.data.message);
        }
      });
    },
    submit() {
      let data = {
        userId: this.userId,
        isAdmin: this.isAdmin,
        // email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
      };
      updateUserDetails(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "회원 정보가 수정 되었습니다.",
            type: "success",
          });
          this.getUserDetail();
        } else {
          return alert(res.data.message);
        }
      });
    },
    getUserWordList() {
      // console.log("this.userId", this.userId);
      let params = {
        userId: this.userId,
        page: this.page,
      };
      fetchUserWordList(params).then((res) => {
        if (res.data.status == 200) {
          if (res.data.status == 200) {
            // console.log(res.data)
            this.wordList = res.data.data;
            this.total = res.data.total;
          } else if (res.data.status == 409) {
            // this.$router.push("/");
            return alert(res.data.message);
          }
        } else {
          alert(res.data.message);
        }
      });
    },
  },
};
</script>
